.cluster-overview {
  @apply 
    p-2 lg:p-4
    flex flex-col gap-4 md:gap-x-8
    md:grid;

    grid-template-columns: 384px auto;
}

.cluster .page-section > .section-header,
.cluster .page-section > div > .section-header {
  @apply rounded border-b border-dotted border-deep-space dark:border-[#C0D7ED] border-opacity-50;
}


.cluster > .page-section:first-of-type > .section-header {
  @apply border-none;
}

.cluster .section-header h1,
.cluster .section-header h2 {
  @apply text-deep-space dark:text-[#C0D7ED];
}

.cluster .page-section > .section-header p {
  @apply text-xl font-normal dark:text-white;
}

.cluster .page-section.cluster-info {
  @apply grid items-start;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 1fr;
}

@media (min-width: 1024px) {

}

.cluster-img {
  @apply 
    md:w-[400px] md:h-[400px]
    object-cover 
    place-self-center
    cursor-pointer 
    content-center
    col-span-full lg:col-span-1;
}

.cluster-overview .info-boxes {
  @apply grid gap-2 col-start-1
    lg:grid-cols-2
    lg:row-start-2 
    lg:col-span-full
    lg:col-start-1
    xl:col-start-2;
}

.soundtrack a {
  @apply no-underline;


  &:hover h2 {
    @apply text-watermelon-500
  }
}