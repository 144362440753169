.leaderboard-pagination {
  @apply text-sm md:text-base md:px-4 flex justify-between items-center gap-4;
}

.leaderboard-pagination button {
  @apply 
    cursor-pointer 
    p-2 md:p-4 
    rounded
    hover:bg-black hover:bg-opacity-50
    disabled:opacity-50 disabled:cursor-not-allowed;
}