.app-header {
  @apply 

    mb-4 md:mb-0
    border-b border-[#0561B7] border-opacity-[0.25]
    dark:border-[#0561B7] dark:border-opacity-50
    bg-[#FCFCFC] dark:bg-deep-space
    z-50;
}

.app-header-content {
  @apply mx-auto container
  px-2 md:px-8
  py-2 md:py-4
    flex justify-between;
}

.app-header-left,
.app-header-right {
  @apply 
    flex items-end gap-4;
}

.app-header-logo img {
  @apply h-[50px] md:h-[60px];
}

.app-header-right .app-header-account-info {
  @apply 
    relative
    flex justify-center items-center gap-4 md:gap-8;
}

.app-header-right .app-header-account-info > button {
  @apply
    h-[40px] w-[40px]
    p-0
    bg-transparent
    border-none
    rounded-full
    cursor-pointer;
}

.app-header-right .app-header-account-info button:focus-visible {
  @apply outline-1 outline-transparent ring ring-[#C0D7ED];
}

.app-header-right .app-header-account-info > button img {
  @apply

    rounded-full;
}

.app-header-right .app-header-account-info .account-menu {
  @apply 
    absolute top-[105%] right-4 
    w-[220px]
    border border-[#0561B7] border-opacity-50
    rounded rounded-tr-none
    bg-white
    dark:bg-[#020021]
    backdrop-filter
    backdrop-blur-lg
    hidden
    overflow-hidden
    z-50;
}

.app-header-right .app-header-account-info .account-menu .app-header-link {
  @apply 
    w-full
    py-4 px-6
    font-bold
    rounded-none
    text-lg
    text-left
    flex items-center gap-5
    transition-all duration-200 ease-in-out
    hover:bg-[#0561B7] hover:bg-opacity-50
    hover:text-deep-space hover:dark:text-white
    hover:no-underline;
}