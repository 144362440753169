.project-list {
  /* @apply w-full */
}

.project-list th {
  @apply p-2 md:p-4 align-middle;
}

.project-list th h2 {
  @apply font-semibold text-2xl text-deep-space dark:text-[#C0D7ED];
}

.project-list td {
  @apply p-2 md:p-4 space-x-2;
}

.project-list thead tr {
  @apply 
    bg-[#F5F5F5] bg-opacity-100 dark:bg-[#0561B7] dark:bg-opacity-10
    font-sans
    text-left;
}

.project-list tr {
  @apply grid whitespace-nowrap;

  grid-template-columns: 1fr 1fr 160px;
}